import axios from "axios";

import React, { useState, useEffect } from "react";
import {
  Card,
  Avatar,
  Typography,
  Button,
  Descriptions,
  Tag,
  Space,
  Modal as AntModal,
  message,
  Popconfirm,
  // Table
} from "antd";
import {
  UserOutlined,
  LinkedinFilled,
  DownloadOutlined,
  FlagOutlined
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { Modal, Form, Icon } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSkills,
  getApplicantDetailsByID,
  updateCanidateDetails,
} from "../api/auth";
import { UserState } from "../features/userSlice";
import { countryCodeSemantic } from "../Data/CountryCode";
import Currency, {
  ctcMap,
  CurrencySymbols,
  defaultCurrency,
} from "../Data/Currency";
import ErrorMessage from "../Error/ErrorMessage";
import SuccessMessage from "../Small-Components/SuccessMessage";
import { Menu, Table } from "semantic-ui-react";
import { EyeOutlined } from "@ant-design/icons";
import { email } from "../features/clientSlice";
import { dropFlag, raiseFlag } from "../api/ZyvkaMember";

// import Column from "antd/lib/table/Column";

const { Title, Paragraph } = Typography;
const { Column } = Table;
const API = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL });

const ApplicantDetails = () => {
  const { id } = useParams();
  const userState = useSelector(UserState);
  const [ApplicantDetails, setApplicantDetails] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [videoResume, setVideoResume] = useState();
  const dispatch = useDispatch();
  const [updateOpen, setUpdateOpen] = useState(false);
  const [fileObj, setFileObj] = useState();
  const [skillOption, setSkillOption] = useState([]);
  const [candidateUpdateForm, setCandidateUpdateForm] = useState({});
  const [flag, setFlag] = useState(false);
  const [flagModal, setFlagModal] = useState(false);
  const [flagText, setFlagText] = useState()
  const [raisedFlagModal, setRaisedFlagModal] = useState(false);
  const [flagPresent, setFlagPresent] = useState(0)
  const [popOpen, setPopOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [queAnswer, setQueAnswer] = useState({
    answer: [],
    question: [],
  });


  // ids for reevaluate  
  const allowedIds = ["clpmm2ehj001moi8n9ktc26sm", "clu8ai1y60085oihmcaksslfs"];

  // date conversion
  const options = { day: "numeric", month: "long", year: "numeric" };
  // data getter & fetcher

  const getApplicant = async () => {
    const res = await getApplicantDetailsByID(
      { id: id },
      dispatch,
      setApplicantDetails,
      setCandidateUpdateForm
    );
    isThereAnyFlag(res);
  };
  const fetchSkills = async () => {
    try {
      await fetchAllSkills(dispatch, setSkillOption);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReEvaluate = async (obj) => {
    setConfirmLoading(true);
    try {
      if (userState?.role === 'superAdmin' || allowedIds?.includes(userState?.userInfo?.id)) {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/jobs/reEvaluation`,
          {
            jobID: obj?.jobID,
            applicantID: id,
            applicationID: obj?.id
          },
          { withCredentials: true }
        );
        if (res) {
          setConfirmLoading(false)
          setIsDetailModalOpen(false)
          setPopOpen(false)
          message.success("Evaluation successfully recorded!!");
        }
      }
    } catch (error) {
      console.log(error);
      alert("An error occurred during re-evaluation. Please try again later.");
      setConfirmLoading(false)
      setIsDetailModalOpen(false)
      setPopOpen(false)
    }
    setConfirmLoading(false)
    setIsDetailModalOpen(false)
    setPopOpen(false)
    setFlag((el) => !el)
  };

  useEffect(() => {
    getApplicant();
    fetchSkills();
    isThereAnyFlag()
  }, [flag]);

  const changeHandler = (e) => {
    setCandidateUpdateForm({
      ...candidateUpdateForm,
      [e.target.name]: e.target.value,
    });
  };
  function formatDateTime(ts) {
    const date = new Date(ts);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedHours = String(hours).padStart(2, '0');

    const formattedDate = `${day}-${month}-${year}`;
    const formattedTime = `${formattedHours}:${minutes}${ampm}`;

    return `${formattedDate} - ${formattedTime}`;
  }

  const isThereAnyFlag = (data) => {
    const tem = data?.job_stats?.reduce((total, stat) => total + (stat?.raiseFlag?.[0]?.isActive === true ? 1 : 0), 0);
    setFlagPresent(tem)
    return tem;
  }
  // console.log(ApplicantDetails, "s")
  const submitHandler = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("applicantID", ApplicantDetails?.id);
    formdata.append("countryCode", candidateUpdateForm?.countryCode);
    formdata.append("firstName", candidateUpdateForm?.firstName);
    formdata.append("lastName", candidateUpdateForm?.lastName);
    formdata.append("mobileNum", candidateUpdateForm?.mobileNum);
    formdata.append("email", candidateUpdateForm?.email);
    // formdata.append("current_company", candidateUpdateForm?.current_company);
    // formdata.append("current_designation", candidateUpdateForm?.current_designation);
    formdata.append("current_salary", candidateUpdateForm?.current_salary);
    formdata.append("expected_salary", candidateUpdateForm?.expected_salary);
    formdata.append("salary_currency", candidateUpdateForm?.salary_currency);
    formdata.append("notice_period", candidateUpdateForm?.notice_period);
    formdata.append("key_skills", JSON.stringify(candidateUpdateForm?.key_skills));
    formdata.append("remarks", candidateUpdateForm?.remarks);
    formdata.append("location", candidateUpdateForm?.location);
    formdata.append("linkedIn_URL", candidateUpdateForm?.linkedIn_URL);
    formdata.append("total_experience", candidateUpdateForm?.total_experience);
    // formdata.append("ctc_negotiable", candidateUpdateForm?.ctc_negotiable);
    formdata.append("file", fileObj);
    formdata.append("oldFile", ApplicantDetails?.resume_urn);
    await updateCanidateDetails(formdata, dispatch, setFlag);
  };
  const [questionAnswerModal, setQuestionAnswerModal] = useState("");

  const OpenQuestionAnswerModal = (id) => {
    setQuestionAnswerModal(id);
  };
  const handleQueAnsModal = () => {
    setQuestionAnswerModal(false);
  };
  const filteredJobStats = (ApplicantDetails?.job_stats || []).filter(
    (obj) => obj?.Score[0]?.TotalScore !== undefined
  );

  const scoresArray = filteredJobStats.map((obj) => {
    return Math.floor(100 * obj?.Score[0]?.TotalScore);
  });
  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const showDetailModal = (id) => {
    setIsDetailModalOpen(id);
  }
  const handleFlagModalOk = async (id) => {
    try {
      const result = await raiseFlag({
        applicantJobStatusId: id, message: flagText, raisedBy: `${userState?.userInfo?.firstName} ${userState?.userInfo?.lastName}`,
        raiseById: `${userState?.userInfo?.id}`
      });
      message?.success("Flag raised successfully, our internal team will start working on it as soon as possible")
    } catch (e) {
      console.log(e)
      message?.error("some error occurred, please try after some time")
    }
    setFlagModal(false);
    setFlag((el) => !el)
  };
  console.log(ApplicantDetails, "asdas");
  const handleDropFlagModalOk = async (id) => {
    try {
      const result = await dropFlag({
        raiseFlagId: id,
        droppedBy: `${userState?.userInfo?.firstName} ${userState?.userInfo?.lastName}`,
        droppedById: `${userState?.userInfo?.id}`
      });
      message?.success("Flag Dropped from scrutiny !!!")
    } catch (e) {
      console.log(e)
      message?.error("some error occurred, please try after some time")
    }
    setRaisedFlagModal(false);
    setFlag((el) => !el)
  };
  const handleDetailModalCancel = () => {
    setIsDetailModalOpen(false);
  };
  return (
    <>
      <br></br>
      <Modal
        title="Video"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
        dimmer="blurring"
        size="small"
        onClose={handleCloseModal}
        closeOnDimmerClick={false}
        open={modalVisible}
        closeIcon
        height={900}
      >
        <Modal.Content>
          <video controls width="100%">
            <source
              src={`${process.env.REACT_APP_SERVER_URL}/api/files/resume/${videoResume}`}
              type=""
            />
            Your browser does not support the video tag.
          </video>
        </Modal.Content>
      </Modal>
      <div>
        <Card
          loading={userState?.isPending}
          style={{
            width: "100%",
          }}
          title={
            <div className="applicant-header">
              <Avatar
                size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 60 }}
                style={{ backgroundColor: "#6855f6" }}
                icon={<UserOutlined />}
              />

              <Title level={2}>
                {ApplicantDetails?.firstName + " " + ApplicantDetails?.lastName}
                {flagPresent > 0 ?
                  <>
                    <span style={{
                      border: "0.15px solid #f04a00",
                      background: "#f04a00",
                      fontWeight: "bold", fontSize: "20px",
                      color: "white",
                      borderRadius: "500px", marginLeft: "10px", padding: "6px",
                    }} ><FlagOutlined /></span>
                    {flagPresent > 1 ? <span style={{
                      color: "#f04a00",
                      padding: "3px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      verticalAlign: 'super',
                    }} >{flagPresent}</span>
                      :
                      null
                    }
                  </> : null}
              </Title>
            </div>
          }
          extra={
            <div className="applicant-header-extra">
              <Icon
                name="edit outline"
                onClick={() => setUpdateOpen(true)}
                style={{
                  cursor: "pointer",
                  fontSize: "20px",
                }}
              ></Icon>
              <a
                href={ApplicantDetails?.linkedIn_URL}
                target="_blank"
                rel="noreferrer"
              >
                <LinkedinFilled
                  style={{
                    fontSize: "40px",
                    color: "rgb(75,79,181)",
                    cursor: "pointer",
                  }}
                />
              </a>
              {/* {
                scoresArray.length > 0 ?
                  <Space >
                    Max Score : {scoresArray.length > 0 ? Math.max(...scoresArray) : ""}
                  </Space> : null
              } */}
              <a
                href={`${ApplicantDetails?.resume_urn?.split(" ").length > 1
                  ? ApplicantDetails?.resume_urn?.split(" ")[1]
                  : `${process.env.REACT_APP_SERVER_URL}/api/files/resume/${ApplicantDetails?.resume_urn}`
                  }`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="primary" icon={<DownloadOutlined />}>
                  Download Resume
                </Button>
              </a>
            </div>
          }
        >
          <div className="applicant-details-content">
            <Descriptions title="" layout="vertical" bordered>
              <Descriptions.Item label="Email">
                {ApplicantDetails?.email}
              </Descriptions.Item>
              <Descriptions.Item label="Mobile">
                {ApplicantDetails?.country_code} {ApplicantDetails?.mobileNum}
              </Descriptions.Item>
              <Descriptions.Item label="Experience">
                {ApplicantDetails?.total_experience} Years
              </Descriptions.Item>
              <Descriptions.Item label="Current CTC">
                {`${ApplicantDetails?.salary_currency
                  ? CurrencySymbols[ApplicantDetails?.salary_currency]
                  : CurrencySymbols["INR"]
                  } ${ApplicantDetails?.current_salary}${ApplicantDetails?.salary_currency
                    ? ctcMap[ApplicantDetails?.salary_currency]
                    : ctcMap["INR"]
                  } P.A.`}
              </Descriptions.Item>
              <Descriptions.Item label="Expected CTC">
                {`${ApplicantDetails?.salary_currency
                  ? CurrencySymbols[ApplicantDetails?.salary_currency]
                  : CurrencySymbols["INR"]
                  } ${ApplicantDetails?.expected_salary}${ApplicantDetails?.salary_currency
                    ? ctcMap[ApplicantDetails?.salary_currency]
                    : ctcMap["INR"]
                  } P.A.`}
              </Descriptions.Item>
              <Descriptions.Item label="Notice Period">
                {ApplicantDetails?.notice_period} days
              </Descriptions.Item>
              <Descriptions.Item label="Skills" span={3}>
                {/* Add function to fetch skills one by one and return them in tags */}
                {userState.isPending === false &&
                  ApplicantDetails?.key_skills?.map((data, idx) => {
                    return <Tag key={idx}>{data}</Tag>;
                  })}
              </Descriptions.Item>
              <Descriptions.Item label="Current Company" span={2}>
                {ApplicantDetails?.current_company
                  ? ApplicantDetails?.current_company
                  : "NA"}
              </Descriptions.Item>
              <Descriptions.Item label="Location">
                {ApplicantDetails?.location ? ApplicantDetails?.location : "NA"}
              </Descriptions.Item>
              <Descriptions.Item label="Added By" span={2}>
                {ApplicantDetails?.creator?.firstName +
                  " " +
                  ApplicantDetails?.creator?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Source" span={3}>
                {ApplicantDetails?.candidateSource
                  ? ApplicantDetails?.candidateSource
                  : "NA"}
              </Descriptions.Item>
              <Descriptions.Item label="Current status" span={4}>
                {/* Return table or here to show candidate round details */}
                {/* Can also be done without table if required */}
                {/* <Table
                  // columns={columns}
                  dataSource={ApplicantDetails?.job_stats}
                  size="small"
                >
                  <Column
                    title="Position"
                    render={(_, record) => {
                      return <span>{record?.jobs?.job_title}</span>;
                    }}
                  />
                  <Column
                    title="Company"
                    render={(_, record) => {
                      return <span>{record?.jobs?.company_name}</span>;
                    }}
                  />
                  <Column
                    title="Round Status"
                    render={(_, record) => {
                      return <span>{record?.current_status}</span>;
                    }}
                  />
                  <Column
                    title="Last Updated"
                    render={(_, record) => {
                      return (
                        <span>
                          {record?.lastUpdated ? new Date(record?.lastUpdated).toLocaleDateString('en-GB', options) : "NA"}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title="Required Questions"
                    render={(_, record) => {
                      return (
                        <span>
                          {record?.lastUpdated ? new Date(record?.lastUpdated).toLocaleDateString('en-GB', options) : "NA"}
                        </span>
                      );
                    }}
                  />
                  <Column
                    title="Reject Reason"
                    render={(_, record) => {
                      return <span>{record?.reject_reason || " ━ "}</span>;
                    }}
                  />
                </Table> */}
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Position</Table.HeaderCell>
                      <Table.HeaderCell>Company</Table.HeaderCell>
                      <Table.HeaderCell>Current Status</Table.HeaderCell>
                      <Table.HeaderCell>Last Updated</Table.HeaderCell>
                      {/* <Table.HeaderCell></Table.HeaderCell> */}
                      <Table.HeaderCell>Evaluation </Table.HeaderCell>
                      {/* {
                        (userState?.role === "superAdmin" || userState?.role === "zyvkaAdmin") &&
                        <Table.HeaderCell>Re-Evaluation </Table.HeaderCell>
                      } */}
                      <Table.HeaderCell>Video - Questions</Table.HeaderCell>
                      {
                        (userState?.role === "superAdmin" || userState?.role === "zyvkaAdmin" || userState?.role === "zyvkaUser") &&
                        <Table.HeaderCell> Last Status Changed By</Table.HeaderCell>
                      }
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {ApplicantDetails &&
                      ApplicantDetails?.job_stats?.map((obj, idx) => {
                        const rowStyle = obj.current_status.includes("Rejected")
                                         ? { backgroundColor: "#fca4ae" }
                                         : obj.current_status.includes("Review")
                                         ? { backgroundColor: "#ffcc80" }
                                         : { backgroundColor: "#aae3ab" };

                        return (
                          <>
                            <Table.Row key={idx} >
                              <Table.Cell>
                                {obj.jobs.job_title}
                                {obj.applicantOtherInfo !== "" && (
                                  <span title={obj.applicantOtherInfo} style={{ cursor: "pointer", color: "red" }}>&nbsp; !</span>
                                )}
                                {
                                  obj?.raiseFlag?.[0]?.isActive === true &&
                                  <span style={{
                                    border: "0.15px solid #f04a00",
                                    background: "#f04a00",
                                    fontWeight: "bold", fontSize: "12px",
                                    color: "white",
                                    borderRadius: "10px", marginLeft: "10px", padding: "4px",
                                  }} ><FlagOutlined /></span>
                                }
                              </Table.Cell>
                              <Table.Cell>{obj.jobs.company_name}</Table.Cell>
                              <Table.Cell style={rowStyle} >{obj.current_status}
                              {
  (userState?.role === "superAdmin" ||
    userState?.role === "zyvkaAdmin" ||
    userState?.role === "zyvkaUser") &&
    (obj?.statusChangedBy?.includes("byZyvkaAi") || obj?.statusChangedBy?.includes("ZyvkaAi") || obj?.statusChangedBy?.includes("reEvaluateByZyvkaAi")) && (
      <span
        style={{
    display: "inline-block",
    border: "1px solid #3A3A3A",
    background: "linear-gradient(145deg, #6E6E6E, #4A4A4A)",
    fontWeight: "bold",
    fontSize: "12px",
    color: "#FFFFFF",
    borderRadius: "10px",
    marginLeft: "10px",
    padding: "4px 8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    textTransform: "uppercase",
        }}
      >
        A.I.
      </span>
    )
}

                              </Table.Cell>
                              <Table.Cell>
                                <p>
                                  {obj?.lastUpdated
                                    ? new Date(
                                      obj?.lastUpdated
                                    ).toLocaleDateString("en-GB", options)
                                    : "NA"}
                                </p>
                              </Table.Cell>

                              {/* <Table.Cell>
                                {obj.reject_reason || "—"}
                              </Table.Cell> */}
                              <Table.Cell>
                                <Button
                                  type="primary"
                                  onClick={() => { showDetailModal(obj?.id) }}
                                >
                                  Show
                                </Button>
                                {/*  candidate evaulation Modal */}
                                <AntModal
                                  title="Candidate Evaluation"
                                  visible={isDetailModalOpen === obj?.id}
                                  onCancel={handleDetailModalCancel}
                                  footer={[
                                    <Button key="back" onClick={handleDetailModalCancel}>
                                      Go Back
                                    </Button>,
                                    (obj?.raiseFlag?.length > 0) && (
                                      <Button
                                        key="submit"
                                        type="primary"
                                        disabled={!((obj?.raiseFlag?.length > 0 && obj?.zyvkaAiEvaluation && obj?.zyvkaAiEvaluation?.decision) || (obj?.zyvkaAiEvaluation?.reject_reason !== ""))}
                                        onClick={() => {
                                          if ((obj?.raiseFlag?.length > 0 && obj?.zyvkaAiEvaluation && obj?.zyvkaAiEvaluation?.decision) || (obj?.zyvkaAiEvaluation?.reject_reason !== "")) {
                                            setRaisedFlagModal(obj?.raiseFlag?.[0]?.id);
                                            handleDetailModalCancel();
                                          }
                                        }}
                                      >
                                        See Already Raised Flag
                                      </Button>),
                                    ((userState?.role === "superAdmin" || allowedIds?.includes(userState?.userInfo?.id)) ) && (
                                      <Popconfirm
                                        open={popOpen}
                                        // disabled={!(obj?.zyvkaAiEvaluation && Object.keys(obj?.zyvkaAiEvaluation)?.length > 0)}
                                        key="popconfirm"
                                        okButtonProps={{
                                          loading: confirmLoading,
                                        }}
                                        title="Are you sure you want to Re Evaluate this Candidate?"
                                        onConfirm={() => {
                                          handleReEvaluate(obj);
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button
                                          type="primary"
                                          danger
                                          //disabled={!(obj?.zyvkaAiEvaluation && Object.keys(obj?.zyvkaAiEvaluation)?.length > 0)}
                                          onClick={() => setPopOpen(true)}
                                        >
                                          Re Evaluate
                                        </Button>
                                      </Popconfirm>
                                    ),
                                    ((obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === false) ||
                                      (obj?.zyvkaAiEvaluation && obj?.raiseFlag?.length <= 0) || (obj?.zyvkaAiEvaluation?.reject_reason !== "")) && (
                                      <Button
                                        type="primary"
                                        danger
                                        onClick={() => {
                                          if ((obj?.zyvkaAiEvaluation && obj?.zyvkaAiEvaluation?.decision) || (obj?.zyvkaAiEvaluation?.reject_reason !== "")) {
                                            handleDetailModalCancel();
                                            setFlagModal(obj?.id);
                                          }
                                        }} >
                                        Raise Flag
                                      </Button>
                                    )
                                  ]}
                                >
                                  {obj.zyvkaAiEvaluation ? (
                                    <>
                                      <div>
                                        <b>Evaluation: </b>
                                        {obj.zyvkaAiEvaluation.reason}
                                      </div>
                                      <div>
                                        <b>AI Score: </b>
                                        {obj.zyvkaAiEvaluation.match_score}
                                      </div>
                                      <div>
                                        <b>Decision: </b>
                                        {obj.zyvkaAiEvaluation.decision}
                                      </div>
                                    </>
                                  ) : obj.reject_reason ? (
                                    <>
                                    <div>
                                    <b>Rejection Reason:</b>{" "}
                                    {obj.reject_reason === "Others"
                                    ? obj.rejectReasonComment
                                    : obj.reject_reason}
                                    </div>
                                    {obj.reject_reason !== "Others" && obj.rejectReasonComment && (
                                      <div>
                                      <b>Additional Feedback:</b> {obj.rejectReasonComment}
                                      </div>
                                      )}
                                      </>
                                  ) : (
                                    <div>No information available</div>
                                  )}
                                </AntModal>


                                {/*  Raise Flag  Modal */}
                                <AntModal
                                  title="Raise Flag"
                                  visible={flagModal === obj?.id}
                                  footer={[
                                    <Button key="back" onClick={() => setFlagModal(false)}>
                                      Cancel
                                    </Button>
                                    ,
                                    <Popconfirm
                                      danger
                                      title="Are you sure you want to riase a flag against AI evaluation ?"
                                      onConfirm={() => { handleDetailModalCancel(); handleFlagModalOk(obj?.id) }}
                                      okText="Yes"
                                      cancelText="No"
                                      disabled={obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === true}
                                    >
                                      <Button danger key="submit" disabled={obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === true}
                                        type="primary" >
                                        Raise Flag
                                      </Button>
                                    </Popconfirm>,
                                  ]}
                                >

                                  <Form loading={userState?.isPending} style={{ marginBottom: "20px" }} >
                                    <Form.TextArea
                                      fluid
                                      name="flagText"
                                      required={true}
                                      placeholder="Enter Your Concern"
                                      label="Message"
                                      value={flagText}
                                      onChange={(e) => setFlagText(e.target.value)}
                                    ></Form.TextArea>
                                  </Form>
                                  <span style={{
                                    background: "black",
                                    fontWeight: "medium",
                                    fontSize: "12px",
                                    marginBottom: "6px",
                                    color: "white",
                                    padding: "3px 6px",
                                    borderRadius: "4px"
                                  }} >

                                    {obj?.raiseFlag?.length > 0 ? `This Candidate Already have ${obj?.raiseFlag?.length} Dropped Flag` : "This Candidate has no raised flag yet"}
                                  </span>
                                </AntModal>

                                <AntModal
                                  title={(userState?.role === 'zyvkaUser' || userState?.role === 'zyvkaAdmin' || userState?.role === 'superAdmin') ? "Drop Flag" : "Raised Flag"}
                                  visible={raisedFlagModal === obj?.raiseFlag?.[0]?.id}
                                  onCancel={() => { setRaisedFlagModal(false) }}
                                  footer={[
                                    <Button key="back" onClick={() => setRaisedFlagModal(false)}>
                                      Cancel
                                    </Button>,
                                    ((userState?.role === 'zyvkaUser' || userState?.role === 'zyvkaAdmin' || userState?.role === 'superAdmin') &&
                                      <Popconfirm
                                        danger
                                        disabled={(obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === false) && !(userState?.role === 'zyvkaUser' || userState?.role === 'zyvkaAdmin' || userState?.role === 'superAdmin')}
                                        title="Are you sure you want to Drop this flag ?"
                                        onConfirm={() => { handleDetailModalCancel(); handleDropFlagModalOk(obj?.raiseFlag?.[0]?.id) }}
                                        okText="Yes"
                                        cancelText="No"
                                      >
                                        <Button primary key="submit" disabled={(obj?.raiseFlag?.[obj?.raiseFlag?.length - 1]?.isActive === false)
                                          && !(userState?.role === 'zyvkaUser' || userState?.role === 'zyvkaAdmin' || userState?.role === 'superAdmin')}
                                          type="primary" >
                                          Drop Flag
                                        </Button>
                                      </Popconfirm>
                                    ),
                                  ]}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%"
                                    }}>
                                    {
                                      obj?.raiseFlag?.map((el, idx) => (
                                        <>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              backgroundColor: "#f0f0f0",
                                              border: "2px solid #007bff50",
                                              borderRadius: "10px",
                                            }}
                                          >
                                            <p
                                              style={{
                                                fontSize: "16px",
                                                textAlign: "center",
                                                padding: "20px",
                                                maxWidth: "600px"

                                              }}
                                            >
                                              {el?.message}
                                            </p>
                                          </div>
                                          <div style={{ display: 'flex', justifyContent: "space-between", width: "100%", alignItems: "center", marginTop: "20px" }}>
                                            <p style={{ fontSize: "18px", color: el?.isActive ? "red" : "green", fontWeight: "bolder" }}>
                                              {el.isActive ? "Under Scrutiny" : (
                                                <span>
                                                  Dropped at <br /> {formatDateTime(el?.droppedAt)}
                                                </span>
                                              )}
                                            </p>

                                            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                              <div style={{ fontSize: "16px", color: "black", fontWeight: "bold" }} >
                                                {el?.raisedBy}
                                              </div>
                                              <div style={{ fontSize: "14px", color: "black", fontWeight: "medium" }} >
                                                {formatDateTime(el?.raisedAt)}
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      ))}
                                  </div>
                                </AntModal>


                              </Table.Cell >
                              <Table.Cell>
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} >
                                  {obj.jobs.isVideoResumeRequired ? (
                                    obj.videoResume ? (
                                      <Button
                                        type="primary"
                                        onClick={() => {
                                          setVideoResume(obj.videoResume);
                                          setModalVisible(true);
                                          // ; getVideo(obj.videoResume)
                                        }}
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-video"><path d="m16 13 5.223 3.482a.5.5 0 0 0 .777-.416V7.87a.5.5 0 0 0-.752-.432L16 10.5" /><rect x="2" y="6" width="14" height="12" rx="2" /></svg>
                                      </Button>
                                    ) : (
                                      "-"
                                    )
                                  ) : (
                                    <div >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-video-off"><path d="M10.66 6H14a2 2 0 0 1 2 2v2.5l5.248-3.062A.5.5 0 0 1 22 7.87v8.196" /><path d="M16 16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2" /><path d="m2 2 20 20" /></svg>
                                    </div>
                                  )}
                                  {ApplicantDetails?.answere[0].quans.length !==
                                    0 ? (
                                    <>
                                      <Button
                                        style={{ marginLeft: "12px" }}
                                        onClick={(e) =>
                                          OpenQuestionAnswerModal(obj?.jobID)
                                        }
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-scroll-text"><path d="M15 12h-5" /><path d="M15 8h-5" /><path d="M19 17V5a2 2 0 0 0-2-2H4" /><path d="M8 21h12a2 2 0 0 0 2-2v-1a1 1 0 0 0-1-1H11a1 1 0 0 0-1 1v1a2 2 0 1 1-4 0V5a2 2 0 1 0-4 0v2a1 1 0 0 0 1 1h3" /></svg>
                                      </Button>
                                      <Modal
                                        dimmer="blurring"
                                        size="small"
                                        onClose={(e) => {
                                          setQuestionAnswerModal(false);
                                        }}
                                        closeOnDimmerClick={false}
                                        open={questionAnswerModal === obj?.jobID}
                                        closeIcon
                                      >
                                        <Modal.Content>
                                          <Space direction="vertical" size={16}>
                                            <Title level={4}>
                                              Required Questions!
                                            </Title>
                                            {ApplicantDetails?.answere[idx]?.quans
                                              .length !== 0 ? (
                                              ApplicantDetails?.answere[
                                                idx
                                              ]?.quans.map((el) => {
                                                return (
                                                  <>
                                                    <Title level={5}>
                                                      Q -{" "}
                                                      {el?.question
                                                        .charAt(0)
                                                        .toUpperCase() +
                                                        el?.question.slice(1)}
                                                    </Title>
                                                    <Paragraph>
                                                      {el?.ans?.answere ||
                                                        "Not Answered Yet"}
                                                    </Paragraph>
                                                  </>
                                                );
                                              })
                                            ) : (
                                              <Paragraph>
                                                {"Not Answered Yet"}
                                              </Paragraph>
                                            )}
                                          </Space>
                                        </Modal.Content>
                                      </Modal>
                                    </>
                                  ) : (
                                    <div style={{ marginLeft: "12px" }} >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-captions-off"><path d="M10.5 5H19a2 2 0 0 1 2 2v8.5" /><path d="M17 11h-.5" /><path d="M19 19H5a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2" /><path d="m2 2 20 20" /><path d="M7 11h4" /><path d="M7 15h2.5" /></svg>
                                    </div>)}
                                </div>
                              </Table.Cell>
                              {
                                (userState?.role === "superAdmin" || userState?.role === "zyvkaAdmin" || userState?.role === "zyvkaUser") &&
                                <Table.Cell>
                                {obj?.statusChangedBy && obj.statusChangedBy.length > 0
                                 ? obj.statusChangedBy[obj.statusChangedBy.length - 1]
                                 : "-"
                                 } 
                                </Table.Cell>
                              }

                            </Table.Row >
                          </>
                        );
                      })}
                  </Table.Body>
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell colSpan="5">
                        <Menu floated="right" pagination>
                          <Menu.Item as="a" icon>
                            <Icon name="chevron left" />
                          </Menu.Item>
                          <Menu.Item as="a">1</Menu.Item>
                          <Menu.Item as="a">2</Menu.Item>
                          <Menu.Item as="a">3</Menu.Item>
                          <Menu.Item as="a">4</Menu.Item>
                          <Menu.Item as="a" icon>
                            <Icon name="chevron right" />
                          </Menu.Item>
                        </Menu>
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Card >

        <Modal
          dimmer="blurring"
          size="large"
          onClose={() => {
            setUpdateOpen(false);
          }}
          closeOnDimmerClick={false}
          open={updateOpen}
          closeIcon
        >
          <Modal.Header>Update</Modal.Header>
          <Modal.Content>
            <Form onSubmit={submitHandler} loading={userState?.isPending}>
              <Form.Group fluid widths="equal">
                <Form.Input
                  fluid
                  name="firstName"
                  required={true}
                  placeholder="Enter First Name"
                  label="First Name"
                  value={candidateUpdateForm?.firstName}
                  onChange={changeHandler}
                ></Form.Input>
                <Form.Input
                  fluid
                  name="lastName"
                  required={true}
                  label="Last Name"
                  placeholder="Enter Last Name"
                  value={candidateUpdateForm?.lastName}
                  onChange={changeHandler}
                ></Form.Input>
              </Form.Group>
              <Form.Input
                type="email"
                required={true}
                placeholder="Enter email"
                name="email"
                label="Email"
                value={candidateUpdateForm?.email}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Group widths={"18"}>
                <Form.Select
                  label="Country Code"
                  name="countryCode"
                  placeholder="country code"
                  options={countryCodeSemantic}
                  value={candidateUpdateForm?.countryCode}
                  onChange={(e, data) => {
                    setCandidateUpdateForm({
                      ...candidateUpdateForm,
                      [data.name]: data.value,
                    });
                  }}
                  required={true}
                ></Form.Select>

                <Form.Input
                  type="text"
                  fluid
                  label="Mobile Number"
                  name="mobileNum"
                  placeholder="Enter mobile number"
                  required={true}
                  width={12}
                  error={
                    candidateUpdateForm?.mobileNum
                      ? candidateUpdateForm?.mobileNum.length > 10 &&
                      "Mobile Number should be 10 digits only"
                      : false
                  }
                  value={candidateUpdateForm?.mobileNum}
                  onChange={changeHandler}
                />
              </Form.Group>
              <Form.Select
                options={skillOption}
                search
                required={true}
                name="key_skills"
                value={candidateUpdateForm?.key_skills}
                // value={jobDetails?.skills_req}
                onChange={(_e, data) => {
                  setCandidateUpdateForm({
                    ...candidateUpdateForm,
                    [data.name]: data.value,
                  });
                }}
                multiple
                label="Key skills"
              ></Form.Select>
              <Form.Input
                type="text"
                required={false}
                placeholder="Enter Linkedin url"
                name="linkedIn_URL"
                label="Linkedin url"
                value={candidateUpdateForm?.linkedIn_URL}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Group widths="equal">
                <Form.Select
                  name="salary_currency"
                  options={Currency}
                  label="salary currency"
                  required={true}
                  value={candidateUpdateForm?.salary_currency}
                  onChange={(e, data) => {
                    setCandidateUpdateForm({
                      ...candidateUpdateForm,
                      [data.name]: data.value,
                    });
                  }}
                ></Form.Select>
                <Form.Input
                  type="number"
                  label="Current ctc"
                  required={true}
                  name="current_salary"
                  value={candidateUpdateForm?.current_salary}
                  onChange={changeHandler}
                ></Form.Input>
                <Form.Input
                  type="number"
                  label="Expected ctc"
                  required={true}
                  name="expected_salary"
                  value={candidateUpdateForm?.expected_salary}
                  onChange={changeHandler}
                ></Form.Input>
              </Form.Group>
              <Form.TextArea
                name="remarks"
                label="Remarks"
                value={candidateUpdateForm?.remarks}
                onChange={changeHandler}
                placeholder="Enter any remarks,if required (100 words max)"
              ></Form.TextArea>
              <Form.Group widths="equal">
                <Form.Input
                  type="number"
                  placeholder="Enter Experience (in years)"
                  label="Experience ( In years )"
                  name="total_experience"
                  value={candidateUpdateForm?.total_experience}
                  onChange={changeHandler}
                ></Form.Input>
                <Form.Input
                  type="number"
                  placeholder="Enter Notice Period"
                  label="Notice Period ( In days )"
                  name="notice_period"
                  required={true}
                  value={candidateUpdateForm?.notice_period}
                  onChange={changeHandler}
                ></Form.Input>
              </Form.Group>
              <Form.Input
                type="text"
                label="Candidate's location"
                placeholder="Enter Candidates Location"
                name="location"
                value={candidateUpdateForm?.location}
                onChange={changeHandler}
              ></Form.Input>
              <Form.Input
                type="file"
                label="Update Resume"
                accept="application/pdf,application/msword,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="file"
                onChange={(e) => {
                  setFileObj(e.target.files[0]);
                }}
              ></Form.Input>
              {userState?.isErrors === true && (
                <ErrorMessage
                  header={"Error Message"}
                  content={userState?.FormErrorMessage?.updateCandidate}
                />
              )}
              {userState?.FormSuccess?.updateCandidate === true &&
                userState?.isErrors === false && (
                  <SuccessMessage
                    header={"success"}
                    content="Candidate's details updated successfully"
                  />
                )}

              <Form.Group widths={"equal"}>
                <Form.Button
                  style={{ width: "100%" }}
                  onClick={() => setUpdateOpen(false)}
                >
                  Cancel
                </Form.Button>
                <Form.Button
                  style={{
                    width: "100%",
                    backgroundColor: "#6855f6",
                    color: "#ffffff",
                  }}
                >
                  Update
                </Form.Button>{" "}
              </Form.Group>
            </Form>
          </Modal.Content>
        </Modal>
      </div >
    </>
  );
};

export default ApplicantDetails;
