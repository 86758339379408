import React, { useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import { Button, Modal, Form, Checkbox, List, Icon, Header, Segment, Popup, Input } from 'semantic-ui-react';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../features/userSlice";
import { CloseCreateChannel } from '../assets';

const ChannelNameInput = ({ channelName = '', setChannelName }) => {
    const handleChange = (event) => {
        event.preventDefault();
        setChannelName(event.target.value);
    }

    return (
        <Form>
            <Form.Field>
                <label>Name</label>
                <input value={channelName} onChange={handleChange} placeholder="channel-name" />
            </Form.Field>
        </Form>
    )
}

const CustomAvatar = ({ name, size }) => {
    const initials = name ? name.charAt(0).toUpperCase() : '?';

    return (
        <div
            style={{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '50%',
                backgroundColor: '#2185d0',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: `${size / 2}px`,
                fontWeight: 'bold',
            }}
        >
            {initials}
        </div>
    );
};

const EditChannel = ({ setIsEditing }) => {
    const { client, channel, setActiveChannel } = useChatContext();
    const [channelName, setChannelName] = useState(channel?.data?.name);
    const [members, setMembers] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isAdding, setIsAdding] = useState(false);
    const [isAddingModal, setAddingModal] = useState(false);
    const [deletingMembers, setDeletingMembers] = useState({});
    const [searchQuery, setSearchQuery] = useState(''); // Add search query state
    // Filter users based on searchQuery
    const filteredUsers = availableUsers.filter(user =>
        (user.username || user.id).toLowerCase().includes(searchQuery.toLowerCase())
    );
    const user = useSelector(selectUser);
    useEffect(() => {
        const fetchMembers = async () => {
            setIsLoading(true);
            console.log("here");
            const response = await channel.queryMembers({});
            setMembers(response.members);
            setIsAdmin(response.members?.some((item) => item.role == "owner" && item.user_id == user.id));
            // setIsAdmin(true);
            console.log('wqd', user, response.members);

            setIsLoading(false);
        };
        fetchMembers();
    }, [client]);
    const updateChannel = async (event) => {
        event.preventDefault();

        if (!isAdmin) return;

        setIsLoading(true);
        const nameChanged = channelName !== (channel.data.name || channel.data.id);

        if (nameChanged) {
            await channel.update({ name: channelName }, { text: `Group name changed to ${channelName}` });
        }

        setChannelName(null);
        setIsEditing(false);
        setIsLoading(false);
    }

    const handleDeleteMember = async (userId) => {
        if (!isAdmin) return;

        setDeletingMembers(prev => ({ ...prev, [userId]: true }));
        try {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/UpdateChannel`, {
                Users: [userId],
                userId: client.userID,
                channelId: channel.id,
                isAdd: 0
            }, { withCredentials: true });
            setMembers(members.filter(member => member.user_id !== userId));
        } catch (error) {
            console.error("Error deleting user:", error);
        } finally {
            setDeletingMembers(prev => ({ ...prev, [userId]: false }));
        }
    };

    const openAddUserModal = async () => {
        if (!isAdmin) return;

        setAddingModal(true);
        try {
            const response = await client.queryUsers(
                { id: { $nin: [...members.map(m => m.user_id), client.userID] } },
                { id: 1 },
                { limit: 100, sort: [{ field: 'username', direction: 1 }] }
            );
            setAvailableUsers(response.users);
            setIsModalOpen(true);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
        setAddingModal(false);
    };

    const handleAddUsers = async () => {
        if (!isAdmin) return;

        setIsAdding(true);
        try {
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/UpdateChannel`, {
                Users: selectedUsers,
                userId: client.userID,
                channelId: channel.id,
                isAdd: 1
            }, { withCredentials: true });
            const newMembers = await channel.queryMembers({ user_id: { $in: selectedUsers } });
            setMembers([...members, ...newMembers.members]);
            setIsModalOpen(false);
            setSelectedUsers([]);
        } catch (error) {
            console.error("Error adding users:", error);
        }
        setIsAdding(false);
    };

    const handleDeleteChannel = async () => {
        if (!isAdmin) return;

        setIsDeleting(true);
        try {
            await axios.delete(`${process.env.REACT_APP_SERVER_URL}/api/chat/deleteChannel`, {
                data: {
                    channelId: channel.id,
                    userId: client.userID,
                    channelType: "team"
                },
                withCredentials: true
            });


            const filter = { members: { $in: [client.userID] } };
            const sort = { last_message_at: -1 };
            const channels = await client.queryChannels(filter, sort, {
                watch: true,
                state: true,
            });

            if (channels.length > 0) {
                setActiveChannel(channels[0]);
            } else {
                setActiveChannel(null);
            }

            setIsEditing(false);
            setIsDeleteModalOpen(false);
        } catch (error) {
            console.error("Error deleting channel:", error);
        } finally {
            setIsDeleting(false);
        }
    };

    const toggleUserSelection = (userId) => {
        setSelectedUsers(prevSelected =>
            prevSelected.includes(userId)
                ? prevSelected.filter(id => id !== userId)
                : [...prevSelected, userId]
        );
    };

    return (
        <div className="edit-channel__container">
            <div className="edit-channel__header" >
                <p style={{
                    margin: '0px'
                }}>Edit Channel</p>
                <div>
                    {isAdmin && (
                        <Popup
                            trigger={
                                <Icon
                                    name='trash alternate outline'
                                    color='red'
                                    onClick={() => setIsDeleteModalOpen(true)}
                                    style={{ cursor: 'pointer', position: "absolute", left: '9rem', top: "1.5rem", fontSize: '1.7em' }}
                                />
                            }
                            content='Delete Channel'
                            position='top center'
                        />
                    )}
                    <CloseCreateChannel setIsEditing={setIsEditing} />
                </div>
            </div>
            {isAdmin && <ChannelNameInput channelName={channelName} setChannelName={setChannelName} />}
            <Segment>
                <Header as='h3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
                    Manage Members
                    {isAdmin && <Button primary disabled={isAdding} onClick={openAddUserModal}>
                        {isAddingModal ? <LoadingOutlined spin /> : 'Add Users'}</Button>}
                </Header>
                <List divided verticalAlign='middle' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    {isLoading ? (
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                    ) : (
                        members.map((member) => (
                            <List.Item style={{ overflowX: 'hidden' }} key={member.user.id}>
                                {isAdmin && member.user_id !== client.userID && (
                                    <List.Content floated='right'>
                                        {deletingMembers[member.user_id] ? (
                                            <LoadingOutlined spin />
                                        ) : (
                                            <Popup
                                                trigger={
                                                    <Icon
                                                        name='trash'
                                                        color='red'
                                                        onClick={() => handleDeleteMember(member.user_id)}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                }
                                                content='Remove User'
                                                position='top center'
                                            />
                                        )}
                                    </List.Content>
                                )}
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    <CustomAvatar name={member.user.username} size={30} />
                                    <List.Content style={{ marginLeft: '10px' }}>
                                        {member.user.username}
                                        {member.user_id === client.userID && ' (You)'}
                                        {member.role === 'admin' || member.role === 'owner' ? ' (Admin)' : ''}
                                    </List.Content>
                                </div>
                            </List.Item>
                        ))
                    )}
                </List>
            </Segment>
            {isAdmin && <Button primary fluid onClick={updateChannel} disabled={isLoading}>
                {isLoading ? <LoadingOutlined spin /> : 'Save Changes'}
            </Button>}

            <Modal size="tiny" open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <Modal.Header>Add Users</Modal.Header>
                <Modal.Content scrolling>
                <Form>
                    <Form.Field>
                        <Input
                            placeholder="Search users..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{ marginBottom: '15px' }}
                        />
                    </Form.Field>
                </Form>
                    <List selection verticalAlign='middle'>
                        {filteredUsers.map(user => (
                            <List.Item
                                key={user.id}
                                onClick={() => toggleUserSelection(user.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <List.Content floated='right'>
                                    <Checkbox
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => toggleUserSelection(user.id)}
                                        onClick={(e) => {

                                            toggleUserSelection(user.id);
                                        }}
                                    />
                                </List.Content>
                                <div style={{ display: "flex" }}>
                                    <CustomAvatar name={user.username || user.email || user.id} size={30} />
                                    <List.Content style={{ marginLeft: '10px' }}>{user.username || user.email || user.id}</List.Content>
                                </div>
                            </List.Item>
                        ))}
                    </List>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    <Button primary onClick={handleAddUsers} disabled={isLoading}>
                        {isAdding ? <LoadingOutlined spin /> : 'Add Selected Users'}
                    </Button>
                </Modal.Actions>
            </Modal>

            <Modal size="tiny" open={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
                <Modal.Header>Confirm Channel Deletion</Modal.Header>
                <Modal.Content>
                    <p>Are you sure you want to delete this channel? This action cannot be undone.</p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setIsDeleteModalOpen(false)}>Cancel</Button>
                    <Button negative onClick={handleDeleteChannel} disabled={isLoading}>
                        {isDeleting ? <LoadingOutlined spin /> : 'Delete Channel'}
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

export default EditChannel;