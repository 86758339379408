import React, { useEffect, useState } from 'react';
import { useChatContext } from 'stream-chat-react';
import axios from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { Button, Modal, Form, Checkbox, List, Input } from 'semantic-ui-react';
import { Spin } from 'antd';

const CreateChannel = ({ createType, setIsCreating }) => {
    const { client, setActiveChannel } = useChatContext();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [channelName, setChannelName] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [availableUsers, setAvailableUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingUsers, setIsFetchingUsers] = useState(true);
    const [searchQuery, setSearchQuery] = useState(''); // Add search query state

    const handleCreateChannel = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/chat/createChatGroup`, {
                Users: [...selectedUsers, client.userID],
                userId: client.userID,
                channelName: channelName,
                createType: createType
            },
                { withCredentials: true });

            if (response.status === 200) {
                const newChannel = client.channel(createType, response.data.data.channel.id);
                await newChannel.watch();
                setChannelName('');
                setIsCreating(false);
                setSelectedUsers([]);
                setActiveChannel(newChannel);
                setIsModalOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false);
    };

    const toggleUserSelection = (userId) => {
        if (createType === "team") {
            setSelectedUsers(prevSelected =>
                prevSelected.includes(userId)
                    ? prevSelected.filter(id => id !== userId)
                    : [...prevSelected, userId]
            );
        } else {
            setSelectedUsers([userId]);
        }
    };

    useEffect(() => {
        const fetchUsers = async () => {
            setIsFetchingUsers(true);
            try {
                const response = await client.queryUsers(
                    { id: { $ne: client.userID } },
                    { id: 1 },
                    { limit: 100, sort: [{ field: 'username', direction: 1 }] }
                );
                setAvailableUsers(response.users);
            } catch (error) {
                console.error("Error fetching users:", error);
            } finally {
                setIsFetchingUsers(false);
            }
        };
        fetchUsers();
    }, [client]);

    // Filter users based on searchQuery
    const filteredUsers = availableUsers.filter(user =>
        (user.username || user.id).toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Modal size="tiny" open={isModalOpen} onClose={() => setIsCreating(false)}>
            <Modal.Header>{createType === 'team' ? 'Create a New Group' : 'Send a Direct Message'}</Modal.Header>
            <Modal.Content scrolling>
                <Form>
                    <Form.Field>
                        <Input
                            placeholder="Search users..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            style={{ marginBottom: '15px' }}
                        />
                    </Form.Field>
                </Form>
                {createType === 'team' && (
                    <Form>
                        <Form.Field>
                            <label>Name</label>
                            <input
                                value={channelName}
                                onChange={(e) => setChannelName(e.target.value)}
                                placeholder="Channel Name"
                            />
                        </Form.Field>
                    </Form>
                )}
                {/* <Input
                    placeholder="Search users..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ marginBottom: '15px' }}
                /> */}
                {isFetchingUsers ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <Spin size="large" />
                    </div>
                ) : (
                    <List selection verticalAlign='middle'>
                        {filteredUsers.map(user => (
                            <List.Item
                                key={user.id}
                                onClick={() => toggleUserSelection(user.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <List.Content floated='right'>
                                    <Checkbox
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => toggleUserSelection(user.id)}
                                        onClick={(e) => {
                                            // e.stopPropagation(); // Prevent parent onClick
                                            toggleUserSelection(user.id);
                                        }}
                                    />
                                </List.Content>
                                <div style={{ display: "flex" }}>
                                    <div
                                        style={{
                                            width: '30px',
                                            height: '30px',
                                            borderRadius: '50%',
                                            backgroundColor: '#2185d0',
                                            color: 'white',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '15px',
                                            fontWeight: 'bold',
                                            marginRight: '10px'
                                        }}
                                    >
                                        {(user.username || user.id).charAt(0).toUpperCase()}
                                    </div>
                                    <List.Content>{user.username || user.id}</List.Content>
                                </div>
                            </List.Item>
                        ))}
                    </List>
                )}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setIsCreating(false)}>Cancel</Button>
                <Button primary onClick={handleCreateChannel} disabled={isLoading || isFetchingUsers}>
                    {isLoading ? <LoadingOutlined spin /> : `${createType === 'team' ? 'Create Channel' : 'Start Chat'}`}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};

export default CreateChannel;
