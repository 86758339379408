import React, { useState, useEffect } from 'react';
import { ChannelList, useChatContext } from 'stream-chat-react';
import TeamChannelList from './chatComponents/TeamChannelList';
import TeamChannelPreview from './chatComponents/TeamChannelPreview';
import ChannelSearch from './chatComponents/ChannelSearch';
import { UserState } from '../../features/userSlice';
import { useSelector } from 'react-redux';
import { Button, message, Modal, Input, Tooltip, Spin, Empty } from 'antd';
import { MessageOutlined,MenuOutlined,CloseOutlined,UsergroupAddOutlined,UserOutlined  } from '@ant-design/icons';
import axios from 'axios';
import { List, Checkbox } from 'semantic-ui-react';

const CustomAvatar = ({ name, size }) => {
    const initials = name ? name.charAt(0).toUpperCase() : '?';

    return (
        <div
            className="custom-avatar"
            style={{
                width: `${size}px`,
                height: `${size}px`,
                borderRadius: '100%',
                backgroundColor: '#6856f5',
                color: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: `${size / 2}px`,
                fontWeight: 'bold',
            }}
        >
            {initials}
        </div>
    );
};



const SideBar = ({ firstName }) => (
    <div className="channel-list__sidebar">
        <div className="channel-list__sidebar__icon1">
            <div className="icon1__inner">
                <CustomAvatar name={firstName} size={45} />
            </div>
        </div>
    </div>
);

const CompanyHeader = ({ onBulkMessage ,name}) => (
    <div className="channel-list__header">
        <p className="channel-list__header__text">Chats</p>
                <Tooltip title="Send Bulk Message" placement="bottomLeft">
                <Button
                  icon
                  basic
                  onClick={onBulkMessage}
                  style={{ marginLeft: "5px", display:"flex",justifyContent:"center",alignItems:"center", boxShadow: "none" , backgroundColor:"#ffffff",border:"none" }}
                >
                  <MessageOutlined
                    className="custom-hover-button"
                    style={{ fontSize: "23px" }}
                  />
                </Button>
              </Tooltip>
    </div>
)

const customChannelTeamFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'team');
}

const customChannelMessagingFilter = (channels) => {
    return channels.filter((channel) => channel.type === 'messaging');
}


const ChannelListContent = ({ isCreating, setIsCreating, setCreateType, setIsEditing, setToggleContainer }) => {
    const [loading, setLoading] = useState(false);
    const [bulkMessageModalVisible, setBulkMessageModalVisible] = useState(false);
    const [bulkMessageText, setBulkMessageText] = useState('');
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [isFetchingUsers, setIsFetchingUsers] = useState(false);
    const { client,setActiveChannel } = useChatContext();
    const user = useSelector(UserState);
    const filters = { members: { $in: [client.userID] } };

    const isGuest = client.user.role === "guest";
   
const CustomEmptyState = ({ type }) => {
    const [hasChannels, setHasChannels] = useState(true);
  
    useEffect(() => {
      const checkForChannels = () => {
        if (client) {
          const activeChannels = Object.values(client.activeChannels);
          setHasChannels(activeChannels.length > 0);
        }
      };
  
      checkForChannels();
    }, [client]);
  
    const emptyStyle = {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      height: '100%',
    };
  
    const iconStyle = {
      fontSize: 56,
      color: '#6856f5',
      marginBottom: '16px',
    };
  
    const descriptionStyle = {
      color: 'rgba(0, 0, 0, 0.45)',
      textAlign: 'center',
    };
  
    if (!hasChannels) {
      if (isGuest && type==="team") {
        return (
          <div style={emptyStyle}>
            <UserOutlined style={iconStyle} />
            <div style={descriptionStyle}>
              <h3>Welcome, {client.user.username}</h3>
              <p>Click on Chat with PSM to start Chatting</p>
            </div>
          </div>
        );
      } 
    }
    if(!isGuest){
      if (type === "messaging") {
        return (
          <div style={emptyStyle}>
            <MessageOutlined style={iconStyle} />
            <div style={descriptionStyle}>
              <h3>No Direct Messages</h3>
              <p>Start a conversation to see messages here</p>
            </div>
          </div>
        );
      }
  
      if(type==="team") { 
        return (
         <div style={emptyStyle}>
          <UsergroupAddOutlined style={iconStyle} />
          <div style={descriptionStyle}>
            <h3>You don't have any Group Chat</h3>
            <p>Create a new group or request to join an existing one</p>
          </div>
        </div>
      );
    }
}
  
    return null;
  };
  
    
  useEffect(() => {
    if (isGuest) {
      const channelListElement = document.querySelector('.str-chat__channel-list');
      if (channelListElement) {
        channelListElement.style.setProperty('height', 'auto', 'important');
        channelListElement.style.setProperty('max-height', '100%', 'important');
        channelListElement.style.setProperty('overflow', 'visible', 'important');
      }

      const chatChannelListElement = document.querySelector('.str-chat-channel-list');
      if (chatChannelListElement) {
        chatChannelListElement.style.setProperty('margin', '0px', 'important');
      }
    }
  }, [isGuest]);
    
    const handleChatWithPSM = async () => {
        setLoading(true);
        try {
          const res = await axios.post(
            `${process.env.REACT_APP_SERVER_URL}/api/chat/startChatToPsm`,
            { id: client.userID },
            { withCredentials: true }
          );
          
          if (res.status === 200) {
            message.success('Chat initiated successfully');
            const filter = { members: { $in: [client.userID] } };
            const sort = { last_message_at: -1 };
            const channels = await client.queryChannels(filter, sort, {
                watch: true,
                state: true,
            });

            if (channels.length > 0) {
                setActiveChannel(channels[0]);
            } else {
                setActiveChannel(null);
            }
          } else {
            message.error(res.data.mesage || 'Failed to initiate chat');
          }
        } catch (error) {
          console.error('Error chatting with PSM:', error);
          message.error("You don't have any psm");
        } finally {
          setLoading(false);
        }
    };

    const handleBulkMessage = () => {
        setBulkMessageModalVisible(true);
        fetchUsers();
    };

    const fetchUsers = async () => {
        setIsFetchingUsers(true);
        try {
            const response = await client.queryUsers(
                { id: { $ne: client.userID } },
                { id: 1 },
                { limit: 100 }
            );
            setAvailableUsers(response.users);
        } catch (error) {
            console.error("Error fetching users:", error);
        } finally {
            setIsFetchingUsers(false);
        }
    };

    const toggleUserSelection = (userId) => {
        setSelectedUsers(prevSelected =>
            prevSelected.includes(userId)
                ? prevSelected.filter(id => id !== userId)
                : [...prevSelected, userId]
        );
    };

    const handleBulkMessageSend = async () => {
        setLoading(true);
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVER_URL}/api/chat/sendChatToPartner`,
                {
                    body: bulkMessageText,
                    partners: selectedUsers,
                    userId: client.userID
                },
                { withCredentials: true }
            );

            if (response.status === 200) {
                message.success('Bulk message sent successfully');
                setBulkMessageModalVisible(false);
                setBulkMessageText('');
                setSelectedUsers([]);
            } else {
                message.error(response.data.message || 'Failed to send bulk message');
            }
        } catch (error) {
            console.error('Error sending bulk message:', error);
            message.error('Failed to send bulk message');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {/* <SideBar firstName={user.userInfo.firstName} /> */}
            <div className="channel-list__list__wrapper">
                {
                    (user?.role !== "partner" && user?.role !== "partnerAdmin") ?
                        <>
                            <CompanyHeader onBulkMessage={handleBulkMessage} name={client.user.username} />
                            <ChannelSearch setToggleContainer={setToggleContainer} />
                            <ChannelList
                                filters={filters}
                                channelRenderFilterFn={customChannelTeamFilter}
                                EmptyStateIndicator={() => (
                                    <CustomEmptyState type="team" />
                                )}
                                List={(listProps) => (
                                    <TeamChannelList
                                        {...listProps}
                                        type="team"
                                        isCreating={isCreating}
                                        setIsCreating={setIsCreating}
                                        setCreateType={setCreateType}
                                        setIsEditing={setIsEditing}
                                        setToggleContainer={setToggleContainer}
                                    />
                                )}
                                Preview={(previewProps) => (
                                    <TeamChannelPreview
                                        {...previewProps}
                                        setIsCreating={setIsCreating}
                                        setIsEditing={setIsEditing}
                                        setToggleContainer={setToggleContainer}
                                        type="team"
                                    />
                                )}
                            />
                            <ChannelList
                                filters={filters}
                                channelRenderFilterFn={customChannelMessagingFilter}
                                EmptyStateIndicator={() => (
                                    <CustomEmptyState type="messaging" />
                                )}
                                List={(listProps) => (
                                    <TeamChannelList
                                        {...listProps}
                                        type="messaging"
                                        isCreating={isCreating}
                                        setIsCreating={setIsCreating}
                                        setCreateType={setCreateType}
                                        setIsEditing={setIsEditing}
                                        setToggleContainer={setToggleContainer}
                                    />
                                )}
                                Preview={(previewProps) => (
                                    <TeamChannelPreview
                                        {...previewProps}
                                        setIsCreating={setIsCreating}
                                        setIsEditing={setIsEditing}
                                        setToggleContainer={setToggleContainer}
                                        type="messaging"
                                    />
                                )}
                            />
                        </>
                        :
                        <>
                            <div style={{ padding: '0.78rem 0.5rem ',display:"flex",justifyContent:"center", borderBottom:"1px solid rgba(128, 128, 128, 0.504)", width:"100%" }}>
                                <Button
                                    type="primary"
                                    onClick={handleChatWithPSM}
                                    loading={loading}
                                >
                                    Chat With PSM
                                </Button>
                            </div>
                            <ChannelList
                                filters={filters}
                                channelRenderFilterFn={customChannelTeamFilter}
                                EmptyStateIndicator={() => (
                                    <CustomEmptyState type="team" />
                                )}
                                Preview={(previewProps) => (
                                    <TeamChannelPreview
                                        {...previewProps}
                                        setIsCreating={setIsCreating}
                                        setIsEditing={setIsEditing}
                                        setToggleContainer={setToggleContainer}
                                        type="team"
                                    />
                                )}
                            />
                            
                            <ChannelList
                                filters={filters}
                                channelRenderFilterFn={customChannelMessagingFilter}
                                EmptyStateIndicator={() => (
                                    <CustomEmptyState type="messaging" />
                                )}
                                Preview={(previewProps) => (
                                    <TeamChannelPreview
                                        {...previewProps}
                                        setIsCreating={setIsCreating}
                                        setIsEditing={setIsEditing}
                                        setToggleContainer={setToggleContainer}
                                        type="messaging"
                                    />
                                )}
                            />
                        </>
                }
            </div>
            <Modal
                title="Send Bulk Message"
                visible={bulkMessageModalVisible}
                onOk={handleBulkMessageSend}
                onCancel={() => {
                    setBulkMessageModalVisible(false);
                    setBulkMessageText('');
                    setSelectedUsers([]);
                }}
                confirmLoading={loading}
            >
                <Input.TextArea
                    rows={4}
                    value={bulkMessageText}
                    onChange={(e) => setBulkMessageText(e.target.value)}
                    placeholder="Enter your message here"
                    style={{ marginBottom: '20px' }}
                />
                {isFetchingUsers ? (
                   <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                   <Spin size="large" />
                 </div>
                ) : (
                    <List selection verticalAlign='middle' style={{ maxHeight: '300px', overflowY: 'auto' }}>
                        {availableUsers.map(user => (
                            <List.Item
                                key={user.id}
                                onClick={() => toggleUserSelection(user.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <List.Content floated='right'>
                                    <Checkbox
                                        checked={selectedUsers.includes(user.id)}
                                        onChange={() => toggleUserSelection(user.id)}
                                        onClick={(e) => {
                                          
                                            toggleUserSelection(user.id);
                                        }}
                                    />
                                </List.Content>
                                <div style={{ display: "flex" }}>
                                    <CustomAvatar name={user.username || user.id} size={30} />
                                    <List.Content style={{ marginLeft: '10px' }}>{user.username || user.id}</List.Content>
                                </div>
                            </List.Item>
                        ))}
                    </List>
                )}
            </Modal>
        </>
    );
}




const ChannelListContainer = ({ setCreateType, setIsCreating, setIsEditing }) => {
    const [toggleContainer, setToggleContainer] = useState(true);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>  
            <div className="channel-list__container">
                <ChannelListContent
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                />
            </div>
            {windowWidth < 960 && toggleContainer && (
                <MenuOutlined
                    className="channel-list__container-toggle"
                    onClick={() => setToggleContainer((prevToggleContainer) => !prevToggleContainer)}
                    style={{
                        position: 'absolute',
                        top: '1.25rem',
                        left: '0.25rem',
                        zIndex: "1",
                        fontSize: '24px',
                        cursor: 'pointer'
                    }}
                />
            )}
            <div className="channel-list__container-responsive"
                style={{ display: toggleContainer ? "none" : "flex", backgroundColor: "#005fff", zIndex: "1" }}
            >
                <ChannelListContent
                    setIsCreating={setIsCreating}
                    setCreateType={setCreateType}
                    setIsEditing={setIsEditing}
                    setToggleContainer={setToggleContainer}
                />
            </div>
        </>
    );
}

export default ChannelListContainer;
