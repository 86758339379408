// export default AllApplicants;
import { Form, Button, Input } from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../features/userSlice";
import { ZyvkaMemberState } from "../features/ZyvkaMemberSlice";
import { getAllApplicantsForPartners } from "../api/partner";
import { useDispatch } from "react-redux";
import { CandidatesJobs } from "./CandidatesJobs";
import { Link } from "react-router-dom";
import { filterApplicants, getAllClients } from "../api/ZyvkaMember";
import { fetchALlPartnerCompanies, fetchAllSkills, fetchAllCurrentComp } from "../api/auth";
import { DatePicker, Modal, Space, Table } from "antd";
import { FlagOutlined } from "@ant-design/icons";
const pageSize = 10;

const AllApplicants = () => {
  const handleExpand = (expanded, record) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };

  const handleActionComplete = () => {
    setExpandedRowKeys([]);
  };
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const dispatch = useDispatch();
  const [flag, setFlag] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [calls, setCalls] = useState(false);
  const userState = useSelector(UserState);
  const AllSkills = userState.gSkills;
  const AllCompany = userState.gCompany;
  const zyvkaState = useSelector(ZyvkaMemberState);
  const AllClients = zyvkaState.AllClients;
  const [keySkills, setKeySkills] = useState();
  const [current_company, set_current_company] = useState();
  const [not_current_company, set_not_current_company] = useState();
  const [clientName, setClientName] = useState();
  const [checkerModal, setCheckerModal] = useState(false);
  const stat_array = ["Candidate Joined", "Position Closed"];
  const [compName, setCompName] = useState();
  const roundOptions = ["Applicant Applied", "Under Review", "Zyvka Shortlisted", "Zyvka Rejected", "Submitted to Client", "Client Shortlisted", "Client Rejected", "Round 1 Selected", "Round 1 Rejected", "Round 2 Selected", "Round 2 Rejected", "Round 3 Selected", "Round 3 Rejected", "Round 4 Selected", "Round 4 Rejected", "Round 5 Selected", "Round 5 Rejected", "Assignment Pending", "Assignment Rejected", "Offer Discussion", "Offer rolled out", "Offer Accepted", "Offer Rejected", "Candidate Withdrawn", "Didn't turn up for Drive", "Candidate Joined", "Candidate Resigned", "Position Closed"]
  const [filterData, setFilterData] = useState();
  const [filterFlag, setFilterFlag] = useState(false);
  const [filterState, setFilterState] = useState({
    min_total_experience: undefined,
    max_total_experience: undefined,
    salary_type: undefined,
    max_current_salary: undefined,
    max_expected_salary: undefined,
    max_notice_period: undefined,
    partner_company_name: undefined,
    applicant_date_creation: undefined,
    current_job_status: undefined,
    //current_company: undefined,
    location: undefined,
    min_score: undefined,
    max_score: undefined,
  })
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [opendrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const fetchApplicantsByPage = async (page) => {
    setCurrentPage(page);
    try {
      if (userState.role === "partnerAdmin" || userState.role === "partner") {
        const res = await getAllApplicantsForPartners(dispatch, page,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            partner_company_name: filterState?.partner_company_name,
            applicant_date_creation: formatDate(filterState?.applicant_date_creation?._d),
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            //current_company: filterState?.current_company,
            location: filterState?.location,
            min_score: filterState?.min_score,
            max_score: filterState?.max_score,
            searchValue: searchValue?.toString().trim()
          });
        setOpenDrawer(false);
        setFilterData(res.data);
        setTotalItems(res?.count || 0);
      } else {
        const res = await filterApplicants(
          page,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            partner_company_name: filterState?.partner_company_name,
            applicant_date_creation: formatDate(filterState?.applicant_date_creation?._d),
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            //current_company: filterState?.current_company,
            location: filterState?.location,
            min_score: filterState?.min_score,
            max_score: filterState?.max_score,
            searchValue: searchValue?.toString().trim()
          });
        setOpenDrawer(false);
        setFilterData(res?.data);
        setTotalItems(res?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchApplicantsInitial = async () => {
    setCurrentPage(1);
    try {
      if (userState.role === "partnerAdmin" || userState.role === "partner") {
        const res = await getAllApplicantsForPartners(dispatch, 1,
          {
          });
        setOpenDrawer(false);
        setFilterData(res.data);
        setTotalItems(res?.count || 0);
      } else {
        const res = await filterApplicants(
          1,
          {
          });
        setOpenDrawer(false);
        setFilterData(res?.data);
        setTotalItems(res?.count || 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const showDrawer = () => {
    setOpenDrawer(!opendrawer);
  };
  // const ChangeFlag = () => {
  //   setFlag((flag) => !flag);
  // };
  const fetchAll = async () => {
    try {
      await fetchAllSkills(dispatch);
      await fetchAllCurrentComp(dispatch);
      await getAllClients(dispatch);
      if (userState.role !== "partnerAdmin" && userState.role !== "partner") {
        const res = await fetchALlPartnerCompanies(dispatch)
        setCompName(res);
      }
    } catch {
      return
    }
  }
  useEffect(() => {
    fetchApplicantsByPage(currentPage);
    // setExpandedRowKeys([]);
  }, [filterFlag, calls, flag]);
  useEffect(() => {
    fetchAll();
    fetchApplicantsInitial();
  }, []);
  //Table Columns
  const columns = [
    {
      title: "First Name",
      dataIndex: "id",
      key: "id",
      render: (value, record, key) => {
        // console.log(record?.job_stats?.reduce((total, stat) => total + (stat?.raiseFlag?.[0]?.isActive === true ? 1 : 0), 0))
        return (
          <Link to={`/dashboard/applicant/${value}`}>
            {
              filterData?.find((val) => {
                return val.id === value;
              })?.firstName
            }
            {
              record?.job_stats?.reduce((total, stat) => total + (stat?.raiseFlag?.[0]?.isActive === true ? 1 : 0), 0)
                >= 1 ?
                <span style={{
                  border: "0.15px solid #f04a00",
                  background: "#f04a00",
                  fontSize: "14px",
                  color: "white",
                  borderRadius: "9999px", marginLeft: "10px", padding: "4px",
                  fontWeight: "bolder",
                }} ><FlagOutlined width="2em" /></span> :
                null
            }
          </Link>
        );
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "id",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "id",
    },
    {
      title: "Mobile",
      dataIndex: "mobileNum",
      key: "id",
    },
    {
      title: "Added By",
      dataIndex: "creator",
      key: "id",
      render: (value, key) => {
        return <>{value?.firstName + " " + value?.lastName}</>;
      },
    },
    {
      title: "Resume",
      dataIndex: "resume_urn",
      key: "id",
      render: (value, key) => {
        return (
          <>
            <a
              href={`${value?.split(" ").length > 1
                ? value.split(" ")[1]
                : `${process.env.REACT_APP_SERVER_URL}/api/files/resume/${value}`
                }`}
              target="_blank" rel="noreferrer"
            >
              Resume
            </a>
          </>
        );
      },
    },
  ];

  //Date Formatter
  const formatDate = (date) => {
    if (!date) return null;
    const inputDate = new Date(date);
    const year = inputDate.getUTCFullYear();
    const month = String(inputDate.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(inputDate.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}T00:00:00.000Z`;
  };
  //console.log(ApplicantData);
  const onFinish = async (values) => {
    try {
      setCurrentPage(1);
      if (userState.role === "partnerAdmin" || userState.role === "partner") {
        const res = await getAllApplicantsForPartners(dispatch, 1,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            partner_company_name: filterState?.partner_company_name,
            applicant_date_creation: formatDate(filterState?.applicant_date_creation?._d),
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            // current_company: filterState?.current_company,
            location: filterState?.location,
            min_score: filterState?.min_score,
            max_score: filterState?.max_score,
            searchValue: searchValue?.toString().trim()
          });
        // console.log(res, "result");
        setOpenDrawer(false);
        setFilterData(res?.data);
        setTotalItems(res?.count || 0);
      } else {
        const res = await filterApplicants(
          1,
          {
            min_total_experience: filterState?.min_total_experience,
            max_total_experience: filterState?.max_total_experience,
            max_current_salary: filterState?.max_current_salary,
            max_expected_salary: filterState?.max_expected_salary,
            max_notice_period: filterState?.max_notice_period,
            key_skills: keySkills || [],
            current_companies: current_company || [],
            notIn_current_companies: not_current_company || [],
            client_name: clientName || [],
            partner_company_name: filterState?.partner_company_name,
            applicant_date_creation: formatDate(filterState?.applicant_date_creation?._d),
            salary_type: filterState?.salary_type,
            current_job_status: filterState?.current_job_status,
            //current_company: filterState?.current_company,
            location: filterState?.location,
            min_score: filterState?.min_score,
            max_score: filterState?.max_score,
            searchValue: searchValue,
          });
        // console.log(res, "result");
        setOpenDrawer(false);
        setFilterData(res?.data);
        setTotalItems(res?.count || 0);
      }
    }
    catch (e) {
      setOpenDrawer(false);
      console.log(e);
    }
  };
  // console.log()

  const resetFilters = () => {
    setFilterState({
      min_total_experience: undefined,
      max_total_experience: undefined,
      salary_type: undefined,
      max_current_salary: undefined,
      max_expected_salary: undefined,
      max_notice_period: undefined,
      partner_company_name: undefined,
      applicant_date_creation: undefined,
      current_job_status: undefined,
      location: undefined,
      min_score: undefined,
      max_score: undefined,
    });
    setKeySkills([]);
    set_current_company([]);
    set_not_current_company([]);
    setClientName([]);
    setFilterOpen(false);
    setFilterData(null);
    setSearchValue("");
    setFilterFlag((filterFlag) => !filterFlag); // To refetch data without filters
  };
  //const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <>
      <div className="">
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            gap: "10px",
            paddingTop: "40px"
          }}
        >
          <Space direction="horizontal" wrap={true} style={{ marginBottom: "40px" }} >
            <Button onClick={showDrawer}>Filter</Button>
            <Input
              placeholder="Search Here"

              style={{
                minWidth: 200,
                maxWidth: 400,
                paddingLeft: 10,
              }}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
            />
            <Button onClick={() => { fetchApplicantsByPage(1) }} style={{ width: 80, background: "#6855F6", color: "white", fontWeight: "600" }}>Search</Button>
          </Space>
        </div>



        {
          opendrawer && (
            <div style={{ width: "100%", padding: "10px", paddingBottom: "40px" }}>
              <Form
                labelCol={{
                  span: 4,
                }}
                layout="horizontal"
                onSubmit={onFinish}
              >
                <Form.Group widths='equal'>
                  <Form.Input fluid name="min_experience" placeholder="min experience (in years)" type="number" value={filterState?.min_total_experience} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      min_total_experience: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                  <Form.Input fluid name="max_experience" placeholder="max experience(in years)" type="number" value={filterState?.max_total_experience} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      max_total_experience: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                  <Form.Input name="max_notice_period" placeholder="notice period(in days)" type="number" value={filterState?.max_notice_period} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      [e.target.name]: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                  <Form.Select
                    name="salary_type"
                    placeholder="salary currency"
                    value={filterState?.salary_type}
                    onChange={(e, { value }) => {
                      setFilterState({
                        ...filterState,
                        salary_type: value,
                      });
                    }}
                    selection
                    options={[
                      { key: 1, text: "$ USD", value: "USD" },
                      { key: 2, text: "₹ INR", value: "INR" },  // Corrected value to "INR"
                      { key: 3, text: "€ EURO", value: "EURO" }, // Corrected value to "EURO"
                    ]}
                  />
                  <Form.Input name="current_ctc" placeholder="current ctc" type="number" value={filterState?.max_current_salary} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      max_current_salary: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                </Form.Group>
                <Form.Group widths={"equal"} >
                  <Form.Input name="expected_salary" placeholder="expected salary" type="number" value={filterState?.max_expected_salary} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      max_expected_salary: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} />
                  <Form.Input
                    name="location"
                    value={filterState?.location}
                    onChange={(e, { value }) => {
                      setFilterState({
                        ...filterState,
                        location: value
                      })
                    }}
                    type="text"
                    placeholder="City, Country"
                    fluid
                  />
                  <Form.Select
                    name="candidate_status"
                    placeholder="candidate status"
                    fluid
                    selection
                    value={filterState?.current_job_status}
                    style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                    options={roundOptions?.map((data, idx) => {
                      return {
                        key: idx,
                        value: data,
                        text: data,
                      }
                    })}
                    onChange={(e, { value }) => {
                      setFilterState({
                        ...filterState,
                        current_job_status: value,
                      });
                    }}
                  ></Form.Select>
                  <Form.Select
                    additionLabel={null}
                    selection
                    fluid
                    search
                    name="key_skills"
                    placeholder="key skills"
                    multiple
                    value={keySkills || []}
                    onChange={(event, { value }) => { setKeySkills(value) }}
                    options={
                      AllSkills?.map((data, idx) => {
                        return {
                          key: idx,
                          text: data.skill,
                          value: data.skill
                        }
                      })
                    }
                  />
                </Form.Group>
                <Form.Group widths={"equal"} >
                  {
                    (userState.role !== 'partnerAdmin' && userState.role !== 'partner') ? (
                      <Form.Select
                        name="partner_company_name"
                        placeholder="partner company name"
                        search
                        style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                        value={filterState?.partner_company_name}
                        onChange={(e, { value }) => {
                          setFilterState({
                            ...filterState,
                            partner_company_name: value,
                          });
                        }}
                        options={
                          compName?.map((data, idx) => {
                            return { key: idx, value: data.company_name, text: data.company_name }
                          })
                        }
                      />
                    ) : null
                  }
                  <Form.Select
                    additionLabel={null}
                    selection
                    fluid
                    search
                    name="current_comapny"
                    placeholder="companies"
                    multiple
                    value={current_company || []}
                    onChange={(event, { value }) => { set_current_company(value) }}
                    options={
                      AllCompany?.map((data, idx) => {
                        return {
                          key: idx,
                          text: data.current_company,
                          value: data.current_company
                        }
                      })
                    }
                  />
                  <Form.Select
                    additionLabel={null}
                    selection
                    fluid
                    search
                    name="not_current_comapny"
                    placeholder="exclude these companies"
                    multiple
                    value={not_current_company || []}
                    onChange={(event, { value }) => { set_not_current_company(value) }}
                    options={
                      AllCompany?.map((data, idx) => {
                        return {
                          key: idx,
                          text: data.current_company,
                          value: data.current_company
                        }
                      })
                    }
                  />
                  <Form.Select
                    additionLabel={null}
                    selection
                    fluid
                    search
                    name="clientName"
                    placeholder="clientName"
                    multiple
                    value={clientName || []}
                    onChange={(event, { value }) => { setClientName(value) }}
                    options={
                      AllClients?.map((data, idx) => {
                        return {
                          key: idx,
                          text: data.name,
                          value: data.name
                        }
                      })
                    }
                  />
                  {/* <Form.Input
                    fluid
                    style={{ minWidth: "150px", minHeight: "30px", height: "40px" }}
                    name="current_company"
                    type="text"
                    value={filterState?.current_company}
                    onChange={(e, { value }) => {
                      setFilterState({
                        ...filterState,
                        current_company: value,
                      });
                    }}
                    placeholder="company"
                  /> */}
                  {/* <Form.Input name="min_score" placeholder="Candidate Score(min)" type="number" value={filterState?.min_score} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      min_score: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} >
                    <input
                      type="number"
                      id="min_score"
                      name="floatInput"
                      min="0"
                      max="100"
                      step="0.01" />
                  </Form.Input>
                  <Form.Input name="max_score" placeholder="Candidate Score(max)" type="number" value={filterState?.max_score} onChange={(e) => {
                    setFilterState({
                      ...filterState,
                      max_score: e.target.value,
                    });
                  }} style={{ minWidth: "150px", minHeight: "30px", height: "40px" }} >
                    <input
                      type="number"
                      id="max_score"
                      name="floatInput"
                      min="0"
                      max="100"
                      step="0.01" />
                  </Form.Input> */}

                  <Form.Field>
                    <DatePicker
                      fluid
                      placeholder="created At"
                      name="created_at"
                      value={filterState?.applicant_date_creation}
                      onChange={(e) => {
                        setFilterState({
                          ...filterState,
                          applicant_date_creation: e,
                        });
                      }}
                      format="YYYY-MM-DD"
                      style={{ width: "100%", border: "none", background: "transparent", minHeight: "30px", height: "40px" }}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group widths={"equal"}>
                </Form.Group>
                <Form.Group widths={"equal"} style={{ display: "flex", justifyContent: "space-between", }} >
                  {/* <div style={{
                    display: "flex", justifyContent: 'center', cursor: "help", alignItems: 'center', borderRadius: '3px', paddingTop: "4px", paddingBottom: "4px", paddingRight: "6px", paddingLeft: "6px"
                  }} onClick={info}> <InfoCircleOutlined /> <p style={{ paddingLeft: "6px", font: "small-caption" }} > Candidate Score</p></div> */}
                  <div style={{ width: "100%", display: "flex", justifyItems: "flex-end", justifyContent: "end", marginRight: "0px", maxHeight: "40px" }}>
                    <Button type="primary" htmlType="submit" style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px", background: "#6855F6", color: "white" }}>
                      Filter
                    </Button>
                    <Button
                      style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
                      dashed
                      type="button"
                      onClick={() => {
                        resetFilters();
                        setFlag((flag) => !flag);
                        setFilterOpen(false);
                      }}
                    >
                      Reset All Filters
                    </Button>
                    <Button
                      type="button"
                      style={{ minWidth: "12%", maxWidth: "200px", marginLeft: "20px" }}
                      onClick={() => {
                        setOpenDrawer(false);
                      }}
                    >
                      Hide Filters
                    </Button>
                  </div>
                </Form.Group>
              </Form>
            </div >
          )
        }
        <Table
          scroll={{ x: true }}
          bordered={true}
          columns={columns}
          // pagination={false} // Disable default pagination since virtual scrolling is used
          pagination={
            userState.role === "partnerAdmin" ? {} : {
              current: currentPage,
              pageSize: pageSize,
              total: totalItems,
              onChange: (page) => {
                setFlag(!flag);
                setCurrentPage(page);
                fetchApplicantsByPage(page);
              },
            }
          }
          dataSource={filterData}
          expandable={{
            expandedRowRender: (record) => (
              <div key={record?.id} style={{ width: "100%" }}>
                <CandidatesJobs setFilterFlag={setFilterFlag} flag={flag} filterFlag={filterFlag} setFlag={setFlag} id={record?.id} data={record?.job_stats} />
              </div>
            ),
            expandedRowKeys: expandedRowKeys,
            onExpand: handleExpand,
          }}
        />
      </div>

    </>
  );
};

export default AllApplicants;